import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Tab } from "..";
import { useDispatch, useSelector } from "react-redux";
import { selectRoute, selectDrilldownReports, selectRoutes, selectIsAdmin } from "../../reducers";
import chanalyticsLogo from '../../assets/logo.png';
import { useHistory } from "react-router-dom";
import { IFrameComponentProps, Route } from "../../models/interfaces/route";
import { DrillDownPage } from "../drill-down-page/drill-down-page";
import { IFrame } from "../main-content/iframe";
import { logout, setRoute } from "../../actions/app";


const Side = styled.div`
  height: 100%;
  border-right: 1px solid #172340;
  background-color: #0c1427;
  padding: 0 24px 24px;
`;
const ChanalyticsLogo = styled.img`
  max-height: 32px;
  margin-left: -6px;
  filter: brightness(0) invert(0.9);
`;

const MenuTitle = styled.div`
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  text-align: left;
  padding-top: 24px;
  display: flex;
  cursor: pointer;
`;
const SubMenuTitle = styled.div`
  padding: 8px 0px 0;
  font-size: 14px;
  font-weight: 400;
  color: #bfc3ce;
  text-align: left;
  display: flex;
  cursor: pointer;
  padding-left: 16px;
`;
const MailToLink = styled.a`
  padding: 8px 0px 0;
  font-size: 14px;
  font-weight: 400;
  color: #bfc3ce;
  text-align: left;
  display: flex;
  cursor: pointer;
  padding-left: 16px;
`;
export const SideMenu: FunctionComponent = () => {
  const selectedRoute = useSelector(selectRoute);
  const drilldownReports = useSelector(selectDrilldownReports);
  const history = useHistory();
  const routes = useSelector(selectRoutes);
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  console.log('selectedAdmin?', isAdmin);

  const createDrilldownSubroutes = () => {
    return [];
  }

  const handleClick = () => {
    history.push('/');
  }
  const openChat = () => {
    // const chat = document.getElementsByClassName('Launcher Launcher-button')[0];
    // if (chat !== undefined) {
    //   (chat as HTMLDivElement).click();
    // }
    window.location.href = 'javascript:Smallchat("open")';
  }
  const handleRouteClick = (route: string | undefined) => {
    if (route !== undefined) {
      dispatch(setRoute({ route: route }));
      history.push(route);
    }
  };
  const renderDashboards = () => {
    const dashRoute = routes.find(route => route.title === 'Dashboards');
    if (dashRoute !== undefined && (dashRoute.componentProps as IFrameComponentProps).srcUrl !== '') {
      return <Tab
        key={'Dashboards'}
        route={dashRoute.path}
        title={dashRoute.title}
        subroutes={dashRoute.subroutes}
      />
    }
  }
  const renderRoutes = () => (
    <>
      {renderDashboards()}
      <MenuTitle>
        Help
      </MenuTitle>
      <SubMenuTitle onClick={() => { window.open('https://chanalytics.io/faq-2', '_blank'); }}>FAQ</SubMenuTitle>
      <SubMenuTitle onClick={() => { openChat() }}>Chat</SubMenuTitle>
      <SubMenuTitle>Phone: 801.299.3239</SubMenuTitle>
      <SubMenuTitle onClick={(e) => { window.location.href = "mailto:support@chanalytics.io"; e.preventDefault() }}>Email: support@chanalytics.io</SubMenuTitle>
      {isAdmin ?
        <MenuTitle onClick={() => handleRouteClick('/admin')}>
          Admin
        </MenuTitle> : null}
      <MenuTitle onClick={() => dispatch(logout())}>Logout</MenuTitle>
    </>
  )
  routes.map((route, key) => {
    let subroutes: Route[] = route.subroutes?.length ? [...route.subroutes] : [];
    // if (route.path === '/drilldown' && drilldownReports.length > 0) {
    //   // {
    //   //   path: "/drilldown/slice-and-dice",
    //   //   title: "Slice & Dice",
    //   //   component: DrillDownPage,
    //   //   componentProps: {
    //   //     graph: 'donut',
    //   //   },
    //   // },


    //   subroutes = drilldownReports.map(report => ({
    //     path: `/drilldown/${report.id}`,
    //     title: report.title,
    //     component: DrillDownPage,
    //     componentProps: {
    //       graph: 'donut'
    //     }
    //   }));
    // }

    return <Tab
      key={key}
      route={route.path}
      title={route.title}
      subroutes={subroutes}
    />
  });
  return (
    <Side>
      <div style={{ height: '32px', padding: '16px 24px', margin: '0 -24px', borderBottom: '1px solid #172340', marginBottom: '16px' }}>
        <ChanalyticsLogo src={chanalyticsLogo} alt="chanalytics logo" onClick={handleClick} />
      </div>
      {renderRoutes()}

    </Side>
  );
};
