import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createDispatchHook, useDispatch, useSelector } from 'react-redux';
import { submitAcceptTos, submitLoginCreds, submitNewPassword, submitResetPassword } from '../../actions/app';
import { selectLogInAttempts } from '../../reducers';
import defaultBackground from '../../assets/login-background.jpg';
import favIcon from '../../assets/favicon.png';
import chanalyticsPurpleLogo from '../../assets/chiopurple2022.png';
import origLogo from '../../assets/logo.png';
import { Snackbar, SnackbarContent } from '@material-ui/core';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://chanalytics.io">
        Chanalytics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#621264',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Login: FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showEmailSent, setShowEmailSent] = useState<boolean>(false);
  const submit = () => {
    dispatch(submitLoginCreds({ username, password, rememberMe }));
  }
  const handleResetPassword = () => {
    dispatch(submitResetPassword({ username }));
    setShowEmailSent(true);
    setShowResetPassword(false);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const urlImage = urlParams.get('imgUrl');
  const showSetNewPassword = urlParams.get('resetPassword');
  const setNewPasswordKey = urlParams.get('key');
  const showTos = urlParams.get('tos');
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);
  const loginWrapper = useRef<HTMLDivElement>(null);
  const attemptedLogins = useSelector(selectLogInAttempts);
  useEffect(() => {
    setUsername(urlParams.get('email') ?? '');
  }, [])
  const backgroundImageSrc = urlImage !== null ? urlImage : defaultBackground; // this should be a comparison with a query param when we have it
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const isNewPasswordValid = (pwd: string) => pwd.length > 8 && password === password2;

  const handleSetNewPassword = () => {
    if (isNewPasswordValid(password) && setNewPasswordKey !== null) {
      dispatch(submitNewPassword({ key: setNewPasswordKey, password: password }))
    }
  }
  const handleAcceptTos = () => {
    if (tosAccepted) {
      console.log('should send accept');
      dispatch(submitAcceptTos());
    }
  }

  const renderContent = () => {
    if (showTos !== null) {
      return renderTosContent();
    }
    if (showSetNewPassword !== null) {
      return renderSetNewPasswordContent();
    }
    if (showResetPassword) {
      return renderResetContent();
    }
    return renderSigninContent();
  }

  const renderSigninContent = () => (
    <>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(changeEvent) => setUsername(changeEvent.currentTarget.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(changeEvent) => setPassword(changeEvent.currentTarget.value)}
          onKeyDown={(eventKey) => eventKey.keyCode === 13 ? submit() : null}
        />
        {attemptedLogins > 0 ? <div style={{ color: 'red' }}>We could not find an account that matches this email and password. Please try again or select Forgot Password.</div> : null}
        <FormControlLabel
          control={<Checkbox checked={rememberMe} color="primary" onChange={() => setRememberMe(!rememberMe)} />}
          label="Remember me"
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => submit()}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2" onClick={() => { setShowResetPassword(true) }}>
              Forgot password?
            </Link>
          </Grid>
          <Grid />
        </Grid>
      </form>
      <Snackbar
        open={showEmailSent}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={10000}
        onClose={() => { setShowEmailSent(false) }}
      >
        <div style={{ backgroundColor: '#53a653', color: 'white', padding: '8px 16px', marginBottom: '72px', borderRadius: '8px' }}>Email with a link to reset password was sent</div>
      </Snackbar>
    </>
  )

  const renderTosContent = () => (
    <>
      <Typography component="h1" variant="h5">
        Terms of Service
      </Typography>
      <div style={{ height: 'calc(100% - 320px)', overflow: 'auto', marginTop: '24px', marginBottom: '16px' }}>
        <p>1.              SAAS SERVICES AND SUPPORT</p>
        <p>1.1            Subject to the terms of this Agreement, Company will use commercially reasonable efforts to provide Customer the Services.</p>
        <p>1.2            Subject to the terms hereof, Company will provide Customer with reasonable technical support services in accordance with Company’s standard practice.</p>
        <p>2.              RESTRICTIONS AND RESPONSIBILITIES</p>
        <p>2.1            Customer will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (“Software”); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels. </p>
        <p>2.2            Further, Customer may not remove or export from the United States or allow the export or re-export of the Services, Software or anything related thereto, or any direct product thereof in violation of any restrictions, laws or regulations of the United States Department of Commerce, the United States Department of Treasury Office of Foreign Assets Control, or any other United States or foreign agency or authority.  As defined in FAR section 2.101, the Software and documentation are “commercial items” and according to DFAR section 252.227‑7014(a)(1) and (5) are deemed to be “commercial computer software” and “commercial computer software documentation.”  Consistent with DFAR section 227.7202 and FAR section 12.212, any use modification, reproduction, release, performance, display, or disclosure of such commercial software or commercial software documentation by the U.S. Government will be governed solely by the terms of this Agreement and will be prohibited except to the extent expressly permitted by the terms of this Agreement.</p>
        <p>2.3            Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Equipment”).  Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer’s knowledge or consent.</p>

        <p>3.              INTELLECTUAL PROPERTY</p>
        <p>3.1            Notwithstanding anything to the contrary in the Agreement, Company shall retain exclusive ownership of the Chanalytics Platform, the Dashboard, and any reports generated through the Chanalytics Platform (“Reports”). During the Term of this Agreement, Customer may only use Reports for internal business purposes; provided, however, that Customer shall not resell, redistribute, share, or provide access to the Chanalytics Platform or the Reports to any third party.  Company reserves all rights not expressly granted to Customer in this Agreement, including without limitation all right, title and interest in any and all patent rights, copyrights, trademark rights and other rights in the Services and the Chanalytics Platform, as well as any improvements, enhancements, improvements, design contributions derivative works, software, applications, inventions or other technology developed in connection with the Services or Implementation Services or support.  Customer shall retain ownership of all data owned by Customer and provided to Company for the purpose of allowing Company to provide the Services (“Customer Data”).  Customer hereby grants Company a nonexclusive license to use the Customer Data to provide the Services during the Term of this Agreement.</p>
        <p>3.2            Notwithstanding anything to the contrary in the Agreement, Company may use the Customer Data and combine the Customer Data with other data to generate aggregate data in anonymized form (“Aggregate Data”), provided that such Aggregate Data shall retain no indicators that would allow a third party to derive the Customer Data from the Aggregate Data.  Company may continue to use, distribute, and share Aggregate Data after the expiration or termination of the Agreement.</p>
        <p>3.3            Notwithstanding anything to the contrary in the Agreement, Customer shall not use the Chanalytics Platform, or the Reports in any manner that is not described in this Agreement, any Statement of Work, any services descriptions, or documentation provided by Company to Customer as part of the Services (“Documentation”) and must not license, sell, rent, lease, lend, transfer, outsource, act as a service bureau for, or otherwise provide access to the Services, the Platform, or the Reports or utilize the Services, the Platform, or the Reports for the benefit of any third party or in any manner that Company reasonably believes is abusive or contrary to applicable law, including without limitation attempting, any attempts to decompile, decipher, disassemble, translate, modify, prepare derivative works of, reverse engineer, or otherwise access the source code of the Services or the Platform; use the Services or the Platform for any unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, or libelous, purpose, or to infringe the intellectual property rights of any third party (including by providing Customer Data to Company that infringes the rights of a third party).</p>
        <p>3.4        Company shall have the right collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and  Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Company offerings.</p>

        <p>4.              CONFIDENTIALITY; PROPRIETARY RIGHTS</p>
        <p>4.1            Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party).  Proprietary Information of Company includes non-public information regarding features, functionality and performance of the Service.  The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.  The Disclosing Party agrees that the foregoing shall not apply with respect to any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.</p>

        <p>5.              PAYMENT OF FEES</p>
        <p>5.1            Customer will pay Company the then applicable fees described in the Order Form for the Services and Implementation Services in accordance with the terms therein (the “Fees”). If Customer’s use of the Services exceeds the Service Capacity set forth on the Order Form or otherwise requires the payment of additional fees (per the terms of this Agreement), Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided herein. Company reserves the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the Initial Service Term or then‑current renewal term, upon thirty (30) days prior notice to Customer (which may be sent by email). If Customer believes that Company has billed Customer incorrectly, Customer must contact Company no later than 60 days after the closing date on the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit.  Inquiries should be directed to Company’s customer support department.</p>
        <p>5.2            Company may choose to bill through an invoice, in which case, full payment for invoices issued in any given month must be received by Company thirty (30) days after the mailing date of the invoice.  Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection and may result in immediate termination of Service. Customer shall be responsible for all taxes associated with Services other than U.S. taxes based on Company’s net income.</p>

        <p>6.              TERM AND TERMINATION</p>
        <p>6.1            Subject to earlier termination as provided below, this Agreement is for the Initial Service Term as specified in the Order Form, and shall be automatically renewed for additional periods of the same duration as the Initial Service Term (collectively, the “Term”), unless either party requests termination at least thirty (30) days prior to the end of the then-current term.</p>
        <p>6.2            In addition to any other remedies it may have, either party may also terminate this Agreement upon thirty (30) days’ notice (or without notice in the case of nonpayment), if the other party materially breaches any of the terms or conditions of this Agreement, provided that either party may immediately terminate this agreement if the other party materially breaches the Section 3 or Section 4 of this Agreement.  Customer will pay in full for the Services up to and including the last day on which the Services are provided. Upon any termination, unless otherwise agreed between the parties in writing, Company will make all Customer Data available to Customer for electronic retrieval for a period of thirty (30) days, but thereafter Company may, but is not obligated to, delete stored Customer Data. All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.</p>

        <p>7.              WARRANTY AND DISCLAIMER</p>
        <p>Company shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimizes errors and interruptions in the Services and shall perform the Implementation Services in a professional and workmanlike manner.  Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company’s reasonable control, but Company shall use reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service disruption.  HOWEVER, COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES.  EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES AND IMPLEMENTATION SERVICES ARE PROVIDED “AS IS” AND COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>

        <p>8.              INDEMNITY</p>
        <p>Company shall hold Customer harmless from liability to third parties resulting from (i) personal injury or property damage caused by Company or (ii) Company’s gross negligence or willful misconduct, provided Company is promptly notified of any and all threats, claims and proceedings related thereto and given reasonable assistance and the opportunity to assume sole control over defense and settlement; Company will not be responsible for any settlement it does not approve in writing.</p>

        <p>9.              LIMITATION OF LIABILITY</p>
        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

        <p>10.            PUBLICITY</p>
        <p>Customer acknowledges and agrees that Company may publicly disclose that Customer is a client of Company. Customer further agrees that Company may display Customer’s logos, promotional graphics, and related marketing designs (the “Client Art”) on Company’s websites and on sales and marketing materials, and hereby grants to Company a worldwide, royalty-free, non-exclusive license to use the Client Art, as well as Customer’s corporate and/or trade name, for such purposes. The parties shall work together in good faith to issue at least one mutually agreed upon press release within 90 days of the Effective Date, and Customer otherwise agrees to reasonably cooperate with Company to serve as a reference account upon request.</p>

        <p>11.            MISCELLANEOUS</p>
        <p>If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.  This Agreement is not assignable, transferable or sublicensable by Customer except with Company’s prior written consent.  Company may transfer and assign any of its rights and obligations under this Agreement without consent.  This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.  No agency, partnership, joint venture, or employment is created as a result of this Agreement and Customer does not have any authority of any kind to bind Company in any respect whatsoever.  In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys’ fees.  All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.  This Agreement shall be governed by the laws of the State of Utah without regard to its conflict of laws provisions.</p>
      </div>
      <div>
        <div style={{ display: 'flex', marginBottom: '-8px' }}>
          <Checkbox color="primary" onChange={() => setTosAccepted(!tosAccepted)} />
          <span style={{ marginRight: '64px', display: 'block' }}>
            I have read and agree with the terms of service above
          </span>
        </div>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!tosAccepted}
          onClick={() => handleAcceptTos()}
        >
          Accept Terms of Service
        </Button>
      </div>

    </>
  )

  const renderResetContent = () => (
    <>
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={username}
        onChange={(changeEvent) => setUsername(changeEvent.currentTarget.value)}
        onKeyDown={(eventKey) => eventKey.keyCode === 13 ? handleResetPassword() : null}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => handleResetPassword()}
      >
        Reset Password
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2" onClick={() => { setShowResetPassword(false) }}>
            Go Back To Login Screen
          </Link>
        </Grid>
        <Grid />
      </Grid>
    </>
  )

  const renderSetNewPasswordContent = () => (
    <>
      <Typography component="h1" variant="h5">
        Set Password
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(changeEvent) => setPassword(changeEvent.currentTarget.value)}
        onKeyDown={(eventKey) => eventKey.keyCode === 13 ? null : null}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Confirm Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(changeEvent) => setPassword2(changeEvent.currentTarget.value)}
        onKeyDown={(eventKey) => eventKey.keyCode === 13 ? submit() : null}
      />
      <Typography component="h6" variant="h6">
        Please enter matching passwords at least 8 characters in length.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isNewPasswordValid(password)}
        onClick={() => handleSetNewPassword()}
      >
        Set Password
      </Button>
      {/* <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2" onClick={() => { setShowResetPassword(false) }}>
            Go Back To Login Screen
          </Link>
        </Grid>
        <Grid />
      </Grid> */}
    </>
  )

  return (
    <div style={{ display: 'flex', width: '100%' }} ref={loginWrapper}>

      {(width) < 600
        ? null
        : <div style={{ minHeight: '100%', width: '100%', backgroundImage: `url(${backgroundImageSrc})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
      }
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <LockOutlinedIcon /> */}
          <img src={origLogo} style={{ width: '240px', marginBottom: '16px', filter: 'brightness(0) invert(56%) sepia(91%) saturate(6953%) hue-rotate(244deg) brightness(94%) contrast(91%)' }} />

          {/* <img src={chanalyticsPurpleLogo} /> */}
          {renderContent()}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
