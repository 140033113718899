import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { Product } from "../models/interfaces/product";
import {
  fetchProducts,
  fetchSammy,
  fetchRetailers,
  fetchCampaigns,
  fetchDrilldownReports,
  saveNewReport,
} from "../services/drilldown";
import { Retailer } from "../models/interfaces/retailer";
import { DrilldownReport } from '../models/interfaces/drilldown-report';
import { ChartTypes } from "../models/enums/chart-types";
import { DropdownOption } from "../models/interfaces/dropdown-option";
import { Route } from "../models/interfaces/route";

const create = actionCreatorFactory("DRILLDOWN");
const createAsync = asyncFactory(create);

export const getProducts = createAsync<{}, Product[]>(
  "GET_PRODUCTS",
  async (params, dispatch) => {
    try {
      return await fetchProducts();
    } catch (e) {
      console.log("error in GET_PRODUCTS", e);
      throw e;
    }
  }
);

export const getSammy = createAsync<
  {},
  {
    allPv: {
      [key: string]: any;
    }[];
    allSku: {
      [key: string]: any;
    }[];
    allTop: {
      [key: string]: any;
    }[];
  }
>("GET_SAMMY", async (params, dispatch) => {
  try {
    return await fetchSammy();
  } catch (e) {
    console.log("error in GET_SAMMY", e);
    throw e;
  }
});

export const getRetailers = createAsync<{}, Retailer[]>(
  "GET_RETAILERS",
  async (params, dispatch) => {
    try {
      return await fetchRetailers();
    } catch (e) {
      console.log("error in GET_RETAILERS", e);
      throw e;
    }
  }
);

export const getCampaigns = createAsync<{}, DropdownOption[]>(
  "GET_CAMPAIGNS",
  async (params, dispatch) => {
    try {
      return await fetchCampaigns();
    } catch (e) {
      console.log("error in GET_CAMPAIGNS", e);
      throw e;
    }
  }
);

export const setDrilldownReport = createAsync<{ title: string, url: string }, Route[]>(
  "SET_DRILLDOWN_REPORT",
  async (params, dispatch) => {
    try {
      return await saveNewReport(params);
    } catch (e) {
      console.log("error in SET_DRILLDOWN_REPORT", e);
      throw e;
    }
  }
);

export const setProductToggle = create<{
  id: number;
  selected: boolean;
}>("SET_PRODUCT_TOGGLE");

export const setRetailerToggle = create<{
  id: number;
  selected: boolean;
}>("SET_RETAILER_TOGGLE");

export const setChartType = create<{
  chartType: ChartTypes;
}>("SET_CHART_TYPE");

export const setCategory = create<string>("SET_CATEGORY");

export const setDimension = create<string>("SET_DIMENSION");

export const setSelectAllProducts = create<void>("SET_SELECT_ALL_PRODUCTS");
export const setSelectNoProducts = create<void>("SET_SELECT_NO_PRODUCTS");
export const setSelectAllRetail = create<void>("SET_SELECT_ALL_RETAIL");
export const setSelectNoRetail = create<void>("SET_SELECT_NO_RETAIL");

export const setStartDate = create<Date>("SET_START_DATE");
export const setEndDate = create<Date>("SET_END_DATE");