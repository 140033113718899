import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { IFrameComponentProps } from "../../models/interfaces/route";
import { useSelector } from "react-redux";
import { selectDrilldownUrl } from "../../reducers";

const IFrameStyled = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export const IFrame: FunctionComponent<any> = (props: IFrameComponentProps) => {
  return (
    <>
      <IFrameStyled
        src={props.srcUrl}
        scrolling={props.doNotScroll === true ? 'no' : 'yes'}
        sandbox={
          "allow-forms allow-same-origin allow-scripts allow-top-navigation"
        }
      />
    </>
  );
};
